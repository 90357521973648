import Layout from "@/Layouts/empty.vue";
import Vue from "vue";
import VueRouter from "vue-router";
// import Routers from "./modules/routers";
Vue.use(VueRouter);

const createRouter = () =>
  new VueRouter({
    mode: "history", // Disabled due to Github Pages doesn'tmode修改为hash support this, enable this if you need.
    scrollBehavior: (to, from, savedPosition) => {
      // console.log("router-change", to, from, savedPosition);
      if (savedPosition) {
        return savedPosition;
      } else {
        return { x: 0, y: 0 };
      }
    },
    base: process.env.BASE_URL,
    routes: [
      {
        // rpath?: 是对路由的一种补偿，在cdn发布后，理论上已经不存在RootPath内容
        //         如果在路由上配置了RootPath，就会导致路由错误，所以使用rpath来补偿
        path: "/:rpath?",
        name: "rpath",
        redirect: "/sz",
        meta: { hidden: false },
        component: Layout,
        children: [
          // ...Routers, // 此位置增加应用路由
          {
            // sz代表什么意思？随意取的, SZ看起来像个合起来的心形
            // "sz/:mkey/:ikey*", // sz/c1/xxx/...， 之后待扩展
            path: "sz",
            name: "ipath",
            meta: { hidden: false, layout: "Layout3" },
            component: () => import("@/views/ipath.vue"),
          },
        ],
      },
      {
        path: "*",
        name: "other",
        redirect: "/sz",
        meta: { hidden: true },
      },
    ],
  });
const router = createRouter();

export default router;
